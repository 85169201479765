* {
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;
    position: relative;
    background-color: transparent;
    // background-image: linear-gradient(180deg, #990000 0%, #040409 100%);
    background-image: linear-gradient(180deg, #00CC00 0%, #5A8EAE 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

::-webkit-scrollbar {
    display: none;
  }

@font-face {
    font-family: 'jcorbs';
    src: url('../assets/fonts/Jcorbs.woff');
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/montserrat.regular.ttf');
}

button {
    border: none;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: unset;
}

input {
    outline: none;
}