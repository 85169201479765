#home {
    max-width: 1200px;
    width: 95%;
    margin-inline: auto;
    font-family: "jcorbs", Sans-serif;
    font-weight: 700;
    color: #fff;

    .title {
        text-align: center;
        font-size: 45px;
        color: #fff;
        margin: 36px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin-left: 20px;
            padding: 10px 15px;
            background-color: #4CAF50;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                background-color: #367c39;
            }
        }

        @media screen and (max-width: 860px) {
            flex-direction: column;
            button {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    .loading {
        padding: 50px 0;
        background: #bf942d;
        color: #7a4f01;
        text-align: center;
        font-size: 28px;
        border-radius: 20px;
        width: 100%;
        margin-top: 100px;

        @media screen and (max-width: 860px) {
            font-size: 18px;
            padding: 12px;
            margin-top: 48px;
            border-radius: 2vw;
        }
    }

    .equipped-grid {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        margin: 20px 0;
        padding: 20px;

        .equipped-card {
            position: relative;
            background: #5a8eae;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height: 150px;

            img {
                width: 100%;
                height: auto;
                border-radius: 15px;
            }

            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;
                background-color: rgba(0, 0, 0, 0.5);

                .description, .character {
                    color: #fff;
                    font-size: 12px;
                }

                .character {
                    color: gold;
                    font-size: 15px;
                    font-weight: bold;
                    text-align: right;
                }

                .description {
                    color: rgb(47, 255, 82);
                    font-size: 15px;
                    font-weight: bold;
                    text-align: left;
                }
            }

            .remove-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: red;
                color: white;
                border: none;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }

    .save-btn {
        display: block;
        margin: 20px auto;
        padding: 10px 20px;
        background-color: green;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            background-color: #367c39;
        }
    }

    .view {
        font-size: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        align-items: start;
        gap: 20px;
        padding: 20px;

        @media screen and (max-width: 840px) {
            grid-template-columns: 1fr;
        }

        .card {
            position: relative;
            margin: 0;
            padding: 30px 20px;
            background: #5a8eae;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 20px;
            overflow: hidden;

            img {
                width: 100%;
                border: 5px #fff solid;
                border-radius: 15px;
            }

            .overlay {
                position: absolute;
                bottom: 35px;
                left: 20px;
                right: 20px;
                display: flex;
                justify-content: space-between;
                width: calc(100% - 40px);

                .character, .description {
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.5);
                }

                .character {
                    color: gold;
                    font-size: 20px;
                    font-weight: bold;
                    text-align: right;
                }

                .description {
                    color: rgb(47, 255, 82);
                    font-size: 20px;
                    font-weight: bold;
                    text-align: left;
                }
            }

            .equip-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: green;
                color: white;
                border: none;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                cursor: pointer;
                z-index: 10; // Ensure it's above other elements
            }
        }
    }
}
