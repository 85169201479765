#header {
    height: 72px;
    background-color: #000;
    font-family: "jcorbs", Sans-serif;

    .container {
        max-width: 1200px;
        box-sizing: border-box;
        height: 100%;
        margin-inline: auto;
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            gap: 12px;
            text-decoration: none; // Ensures no underline on links
            color: #fff; // Ensures text color is white for all links
        }

        .connect-btn {
            display: flex;
            align-items: center;
            text-align: end;

            button, .mint-btn {
                padding: 10px 20px;
                font-family: "jcorbs", Sans-serif;
                font-size: 16px;
                font-weight: 700;
                border-radius: 3px;
                transition: all .3s;
                border: none;
                margin-left: 10px; // Provides spacing between buttons

                &:hover {
                    background-color: #cc0000;
                    color: #fff;
                }
            }

            .mint-btn {
                background-color: green; // Mint button background color
                &:hover {
                    background-color: darken(green, 10%);
                }
            }

            .progress-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 10px;
                font-size: 12px;
                color: white;

                .progress-title {
                    text-align: center;
                    margin-bottom: 5px;
                }

                .progress-bar {
                    width: 200px;
                    height: 10px;
                    background-color: #ddd;
                    border-radius: 5px;
                    position: relative;

                    .progress-fill {
                        height: 100%;
                        background-color: green;
                        border-radius: 5px;
                    }

                    .tooltip {
                        position: absolute;
                        top: -25px; // Adjust position to fit your design
                        left: 50%;
                        transform: translateX(-50%);
                        display: none;
                        padding: 3px 6px;
                        background-color: black;
                        color: gold;
                        border-radius: 5px;
                        font-size: 20px;
                    }

                    &:hover .tooltip {
                        display: block;
                    }
                }
            }

            @media screen and (max-width: 860px) {
                flex-direction: column;
                align-items: flex-end;

                button, .mint-btn {
                    font-size: 14px;
                    padding: 8px 16px;
                    margin-top: 5px; // Provides spacing between buttons on smaller screens
                }
            }
        }
    }

    .msg-box-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(17, 17, 17, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;

        .msg-box {
            box-sizing: border-box;
            max-width: 90vw;
            min-width: 30vw;
            padding: 24px;
            font-family: "jcorbs", Sans-serif;
            font-size: 30px;
            font-weight: 600;
            color: #fff;
            background-color: #5a8eae;
            border-radius: 12px;
            box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.67);

            @media screen and (max-width: 860px) {
                padding: 18px;
                box-shadow: 0px 0px 3vw rgba(255, 255, 255, 0.67);
            }

            .top {
                padding: 0 36px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 24px;
                font-size: 30px;
                border-bottom: 2px #fff solid;

                @media screen and (max-width: 860px) {
                    padding: 0 24px 12px;
                    font-size: 4.5vw;
                }

                button {
                    width: 30px;
                    height: 30px;
                    border-radius: 8px;

                    &:hover {
                        background-color: #CC0000;
                    }
                }
            }

            .main {
                margin: 24px auto;
                text-align: center;
                font-family: "Montserrat", Sans-serif;
                font-size: 24px;
                color: #111111;

                @media screen and (max-width: 860px) {
                    margin: 18px auto;
                    font-size: 4vw;
                }
            }

            .bottom {
                display: flex;
                justify-content: space-around;
                align-items: center;

                @media screen and (max-width: 860px) {
                    flex-direction: column;
                    gap: 4vw;
                }

                .change-btn, .disconnect-btn {
                    padding: 10px 20px;
                    font-family: "jcorbs", Sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    border-radius: 3px;
                    transition: all .3s;

                    &:hover {
                        background-color: #5a8eae;
                        color: #fff;
                        box-shadow: 5px -5px 15px #fff;
                    }

                    @media screen and (max-width: 860px) {
                        font-size: 14px;
                        padding: 8px 16px;
                        width: 80%;

                        &:hover {
                            box-shadow: 0px 0px 3vw rgba(255, 255, 255, 0.67);
                        }
                    }
                }

                .disconnect-btn {
                    &:hover {
                        background-color: #cc0000;
                        color: #fff;
                    }
                }
            }
        }
    }
}
